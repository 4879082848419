.event {
	padding: 1rem;
	padding-top: 4rem;
	padding-right: 10rem;
	padding-bottom: 3rem;
	gap: 1rem;
	max-width: 48rem;
	width: 100%;
	cursor: pointer;
	perspective: 500px;
	filter: blur(0) opacity(1) grayscale(0.5);
	background: linear-gradient(var(--accent), var(--lipstick));
	border-radius: 1rem;

	&:first-child {
		max-width: 64rem;
		padding: 2rem;
		padding-top: 8rem;
		padding-right: 16rem;
		padding-bottom: 5rem;
		filter: blur(0) opacity(1) grayscale(0);

		img {
			&:nth-child(1) {
				filter: brightness(1);
			}
			&:nth-child(2) {
				height: 10rem;
				bottom: -5rem;
			}

			&:nth-child(3) {
				width: 16rem;
				right: -6rem;
			}
		}
	}

	&:hover {
		transform: rotateX(4deg) translateZ(0.5rem) scale(1.05);
		filter: blur(0) opacity(1) grayscale(0);

		img {
			&:nth-child(1) {
				filter: brightness(1);
			}
			&:nth-child(2) {
				transform: scale(1.2) rotate(-2deg);
			}

			&:nth-child(3) {
				right: -7rem;
				transform: scale(1.2) rotate(5deg);
			}
		}
	}

	h1,
	h2 {
		text-wrap: balance;
		color: var(--white);
	}

	p {
		color: #fff;
	}

	img {
		width: 100%;
		position: absolute;

		&[src=""] {
			display: none;
		}

		&:nth-child(1) {
			height: 100%;
			inset: 0;
			border-radius: 1rem;
			object-fit: cover;
			z-index: -1;
			filter: brightness(0.5);
		}

		&:nth-child(2) {
			height: 6rem;
			bottom: -3rem;
			object-fit: contain;

			&[alt*="nagasaki" i][alt*="logo" i] {
				height: 4rem;
			}
		}

		&:nth-child(3) {
			height: 100%;
			right: -3rem;
			width: 12rem;
			object-fit: contain;
			bottom: 0;
			width: 13rem;
		}
	}
}
