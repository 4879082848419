section:has(.categories) {
	padding: 4rem;
	gap: 1rem;
	width: calc(100vw - 1rem);

	h1,
	p {
		max-width: 48rem;
		text-align: center;
		text-wrap: balance;
	}

	.categories {
		width: 100%;
		flex-direction: row;
		align-items: center;
		gap: 2.5rem;
		padding: 4rem 0;
		padding-right: 3rem;
		overflow-x: auto;
		border-radius: 0.25rem;
		max-width: 64rem;
	}
}

.summary {
	display: flex;
	flex-direction: row;
	justify-content: center;
	overflow: hidden;
	padding: 8rem 4rem;
	width: calc(100vw - 1rem);
	gap: 1rem;

	* {
		text-align: center;
		user-select: none;
		text-wrap: balance;
	}

	div {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1rem;
		padding: 2rem;
		max-width: 32rem;
	}

	img {
		width: 245px;
		height: 367px;
		transform: scale(1.5);

		&:first-child {
			transform-origin: right;
		}

		&:last-child {
			transform-origin: left;
		}
	}
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--accent);
	display: flex;
	justify-content: start;
	align-items: center;
	gap: 1rem;
	padding-top: 8rem;
	z-index: 1;
	display: none;

	h1,
	p {
		color: white;
		text-align: center;
	}

	img:last-child {
		position: absolute;
		bottom: 12rem;
		width: 100%;
		height: 15%;
		object-fit: scale-down;
	}
}
