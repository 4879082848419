.product {
	min-height: calc(100vh - 6.5rem);
	flex-direction: row;
	padding: 4rem 8rem;
	padding-bottom: 16rem;
	gap: 4rem;
	align-items: start;
	justify-content: center;

	> :first-child {
		gap: 1rem;
		max-width: 35rem;

		* {
			user-select: none;
		}

		> img {
			height: 24rem;
			min-width: 35rem;
			object-fit: contain;
			mix-blend-mode: multiply;
			padding: 1rem;
		}

		> div:first-child {
			position: relative;
			width: 35rem;

			button + img:has(+ button) {
				height: 24rem;
				object-fit: contain;
				padding: 1rem;
			}

			button {
				background: var(--contrast--050);
				height: 2rem;
				top: calc(50% - 1rem);
				padding: 0.25rem 0;

				img {
					height: 1.5rem;
					object-fit: contain;
					filter: brightness(0);
					opacity: 0.75;
				}

				&:hover {
					padding: 0.25rem 0;
					box-shadow: none;
					transform: none;
					background: var(--contrast--100);
					border-radius: 0.5rem;

					img {
						filter: brightness(1);
						opacity: 1;
					}
				}

				&:active {
					transform: scale(0.8);
				}
			}

			> :first-child {
				position: absolute;
				left: 0;

				&:hover {
					img {
						transform: translateX(-0.5rem);
					}
				}
			}

			> :last-child {
				position: absolute;
				right: 0;

				&:hover {
					img {
						transform: translateX(0.5rem);
					}
				}
			}
		}

		> div:last-child {
			flex-direction: row;
			overflow: auto;
			scroll-snap-type: x mandatory;
			gap: 1rem;
			padding-bottom: 1rem;
			width: 35rem;

			div {
				background: linear-gradient(
					var(--contrast--050) 0%,
					var(--lipstick--100) 100%
				);
				height: 8rem;
				min-width: 8rem;
				border-radius: 0.5rem;

				img {
					height: 8rem;
					width: 100%;
					object-fit: contain;
					mix-blend-mode: multiply;
					cursor: pointer;
					padding: 0.5rem;
					scroll-snap-align: start;
					border-radius: 0.5rem;

					&.current {
						cursor: not-allowed;
						box-shadow: inset 0 0 0 0.25rem var(--accent);
						filter: opacity(0.5);
					}
				}
			}
		}
	}

	> :last-child {
		gap: 2rem;
		align-items: end;
		max-width: 32rem;

		div {
			width: 100%;
		}

		span {
			width: 100%;
			font-size: 1.5rem;
			font-weight: 600;
			text-align: left;
			font-family: "Poppins";
			color: var(--thunder);
			display: inline-flex;
			align-items: center;
			gap: 0.5rem;

			span {
				font-size: 1rem;
				font-weight: 500;
				color: var(--contrast);
				opacity: 0.5;
				text-decoration-line: line-through;
			}
		}

		strong {
			font-weight: 500;
		}

		*:not(h1, h5, p, strong, ) {
			user-select: none;
		}
	}
}
