/* Base HTML boilerplate */

*,
*::before,
*::after {
	padding: 0;
	margin: 0;
	font-size: 1rem;
	border: none;
	outline: none;
	box-sizing: border-box;
	font-family: "Noto Sans", sans-serif;
	font-weight: 400;
	transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1);
}

/* Enables smooth scrolling */

@media (prefers-reduced-motion: no-preference) {
	html {
		scroll-behavior: smooth;
	}
}

/* Global variables */

:root {
	--white: #fff;
	--contrast: #0f0f0f;
	--contrast--900: #272727;
	--contrast--800: #3f3f3f;
	--contrast--700: #575757;
	--contrast--600: #6f6f6f;
	--contrast--500: #878787;
	--contrast--400: #9f9f9f;
	--contrast--300: #b7b7b7;
	--contrast--200: #cfcfcf;
	--contrast--100: #e7e7e7;
	--contrast--050: #f3f3f3;
	--contrast--000: #0f0f0f00;
	--lipstick: #f12d2d;
	--lipstick--900: #f24242;
	--lipstick--800: #f45757;
	--lipstick--700: #f56c6c;
	--lipstick--600: #f78181;
	--lipstick--500: #f89696;
	--lipstick--400: #f9abab;
	--lipstick--300: #fbc0c0;
	--lipstick--200: #fcd5d5;
	--lipstick--100: #feeaea;
	--lipstick--050: #fef5f5;
	--lipstick--000: #f12d2d00;
	--accent: #ff4242;
	--accent--900: #ff5555;
	--accent--800: #ff6868;
	--accent--700: #ff7b7b;
	--accent--600: #ff8e8e;
	--accent--500: #ffa1a1;
	--accent--400: #ffb4b4;
	--accent--300: #ffc6c6;
	--accent--200: #ffd9d9;
	--accent--100: #ffecec;
	--accent--050: #fff6f6;
	--accent--000: #ff424200;
	--thunder: #256eff;
	--radius--min: 4px;
}

/* Disables text inflation algorithm for some mobile web browsers */

html {
	-webkit-text-size-adjust: none;
	text-size-adjust: none;
}

/* Scrollbar */

::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
	position: absolute;
	width: 1rem;
	height: 1rem;
	border-radius: 0.5rem;
	border: 0.25rem solid transparent;
}

::-webkit-scrollbar:hover {
	box-shadow: inset 0 0 0 0.5rem var(--contrast--200);
}

::-webkit-scrollbar-thumb {
	box-shadow: inset 0 0 0 0.5rem var(--contrast--500);
}

::-webkit-scrollbar-thumb:hover {
	box-shadow: inset 0 0 0 0.5rem var(--accent);
}

/* All images will be contained */

img {
	display: block;
	max-width: 100%;
}

/* Disables outline feedback when elements are being focused, but only for mouse users */
/* (keyboard users will still see this feedback when focusing on elements). */

:focus:not(:focus-visible) {
	outline: none;
}

/* Headings, labels & texts */

h1,
h2,
h3,
h4,
h5,
h6 {
	letter-spacing: -0.05em;
}

h1 {
	font-size: 2.5rem;
	font-weight: 900;
	line-height: 3rem;
}

h2 {
	font-size: 2rem;
	font-weight: 800;
	line-height: 2.5rem;
}

h3 {
	font-size: 1.75rem;
	font-weight: 700;
	line-height: 2rem;
}

h4 {
	font-size: 1.5rem;
	font-weight: 600;
	line-height: 2rem;
}

h5 {
	font-size: 1.25rem;
	font-weight: 600;
	line-height: 1.5rem;
}

h6 {
	font-size: 1rem;
	font-weight: 600;
	line-height: 1rem;
}

p {
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
}

font {
	color: var(--accent);
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
	letter-spacing: inherit;
}

span {
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
	letter-spacing: inherit;
}

/* Basic structure */

#root {
	transition: none;
}

header + div:has(footer) {
	min-height: calc(100vh - 6.5rem);
	max-height: calc(100vh - 6.5rem);
	min-width: calc(100vw);
	max-width: calc(100vw);
	overflow-y: auto;
	overflow-x: hidden;
	display: grid;

	> * {
		max-width: 100vw;
	}
}

section.not-found {
	justify-content: center;
	min-height: calc(100vh - 12.5rem);
	gap: 1rem;
	padding: 1rem;

	h1,
	p {
		text-align: center;
		text-wrap: balance;
		max-width: 48rem;
	}
}

section,
main {
	display: flex;
	flex-direction: column;
	align-items: center;
}

div {
	display: flex;
	flex-direction: column;
}

ul {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

li {
	list-style: none;
}

.row {
	flex-direction: row;
	gap: 1rem;
	width: 100%;
	align-items: flex-end;
}

.row:has(> button) {
	justify-content: end;
}

button,
a {
	line-height: 1.5rem;
}

/* Horizontal rule (separator) */

hr {
	box-shadow: 0 0 0 1px var(--contrast--050);
	border-radius: 1px;
	width: 100%;
	align-self: center;
}

/* Fieldsets that wrap around checkboxes & radio buttons */

fieldset:has([type="checkbox"], [type="radio"]) {
	gap: 1rem;
	width: 100%;
	border-radius: 0.5rem;
	padding: 1rem;
	display: flex;
	flex-direction: row;
	border: solid 2px var(--contrast--200);
	@media (max-width: 640px) {
		flex-direction: column;
	}
}

legend {
	color: var(--contrast--800);
	padding: 0 0.5rem;
	user-select: none;
	line-height: 1.5rem;
	font-weight: 600;
}

fieldset:has([type="checkbox"], [type="radio"]):hover {
	border: solid 2px var(--contrast--800);
}

fieldset:has([type="checkbox"], [type="radio"]):focus-within {
	border: solid 2px var(--zima--800);
}

*:not(header) > :is(div, section) > button {
	background: var(--accent);
	color: var(--white);
	border: none;
	border-radius: 0.25rem;
	padding: 0.5rem 1rem;
	font-weight: 500;
	cursor: pointer;
	width: fit-content;
	transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1);

	&:hover {
		color: var(--white);
		box-shadow: 0 4px 0 0 var(--lipstick--500);
		background: var(--lipstick);
		transform: translateY(-0.25rem);

		&:active {
			transform: translateY(0);
			box-shadow: 0 0 0 0 var(--lipstick--500);
		}
	}

	&:disabled {
		opacity: 0.5;
	}
}
