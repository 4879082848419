.incrementer {
	flex-direction: row;
	box-shadow: inset 0 0 0 2px var(--contrast--050);
	max-width: fit-content;
	border-radius: 0.5rem;
	overflow: hidden;
	min-width: 7.5rem;
	justify-content: space-between;
	line-height: 2.5rem;
	font-weight: 500;
	height: 2.5rem;

	* {
		user-select: none;
	}

	button {
		width: 2.5rem;
		height: 2.5rem;
		padding: 0;
		align-items: center;
		justify-content: center;
		font-size: 1.5rem;
		line-height: 2.5rem;
		background: transparent;
		color: var(--contrast);
		border-radius: 0.5rem;

		&:hover {
			background: var(--contrast--100);
			transform: translate(0);
			box-shadow: none;
			border-radius: 0;
		}

		&:first-child:active:not(:disabled) {
			transform: translateX(-2px);
		}

		&:last-child:active:not(:disabled) {
			transform: translateX(2px);
		}

		&:disabled {
			color: var(--contrast--200);
			cursor: not-allowed;
			background: transparent;
			filter: grayscale(1) opacity(0.25);
		}
	}
}
