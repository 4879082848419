header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	position: sticky;
	padding: 0 8rem 0.5rem;
	box-shadow: inset 0 -0.5rem 0 0 var(--accent);
	min-width: calc(100vw);
	max-width: calc(100vw);
	overflow: hidden;

	* {
		transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1);
		user-select: none;
	}

	nav {
		display: flex;
		flex-direction: row;
		gap: 1rem;
	}

	div {
		flex-direction: row;
		gap: 1rem;
	}

	img {
		cursor: pointer;
		padding: 0.5rem;
	}

	span {
		color: var(--white);
	}

	a {
		text-decoration: none;
		border-radius: 0.25rem;
		padding: 0.25rem 0.5rem;
		color: var(--contrast--800);
		font-weight: 600;
		padding: 0.5rem;
		text-wrap: nowrap;
		text-overflow: ellipsis;

		&:visited {
			color: var(--contrast--800);
		}

		&:hover {
			color: var(--white);
			box-shadow: 0 4px 0 0 var(--accent--500);
			background: var(--accent);
			transform: translateY(-0.25rem);
		}

		&:active {
			transform: translateY(0);
			box-shadow: 0 0 0 0 var(--accent--500),
				inset 0 4px 0 0 var(--lipstick--500);
			background: var(--lipstick);
			padding-bottom: 0.25rem;
			padding-top: 0.75rem;
		}

		&[current="true"] {
			color: var(--white);
			box-shadow: inset 0 0 0 0 var(--lipstick--500), 0 0 0 0 var(--accent--500);
			background: var(--lipstick);
			transform: translateY(0);
			cursor: default;

			&:active {
				box-shadow: inset 0 4px 0 0 var(--lipstick--500),
					0 0 0 0 var(--accent--500);
				filter: brightness(0.95);
			}
		}

		&:last-child {
			color: var(--white);
			box-shadow: 0 4px 0 0 var(--contrast--500),
				inset 0 0 0 0 var(--contrast--500);
			background: var(--contrast);
			transform: translateY(-0.25rem);

			&:hover {
				box-shadow: 0 0 0 0 var(--contrast--500),
					inset 0 0 0 0 var(--contrast--500);
				background: var(--contrast--800);
				transform: translateY(0);
			}

			&:active {
				background: var(--contrast);
				box-shadow: 0 0 0 0 var(--contrast--500),
					inset 0 4px 0 0 var(--contrast--700);
				filter: brightness(0.95);
			}

			&[current="true"] {
				transform: translateY(0);
				box-shadow: 0 0 0 0 var(--contrast--500),
					inset 0 0 0 0 var(--contrast--700);

				&:active {
					background: var(--contrast);
					box-shadow: 0 0 0 0 var(--contrast--500),
						inset 0 4px 0 0 var(--contrast--700);
					filter: brightness(0.95);
				}
			}
		}
	}

	button {
		background: transparent;
		padding: 0;
		position: relative;

		img {
			filter: brightness(0);
		}

		&:hover {
			transform: translateY(-2px);
			padding: 0;
			background: transparent;
			box-shadow: none;

			img {
				transform: rotate(-10deg);
				filter: brightness(1);
			}
		}

		span {
			position: absolute;
			bottom: -0.5rem;
			right: -0.5rem;
			background: var(--accent);
			font-size: 10px;
			line-height: 1rem;
			width: 1rem;
			height: 1rem;
			border-radius: 100%;
			box-shadow: 0 0 0 0 var(--white);
		}

		&[open] {
			transform: translateY(-2px);
			padding: 0;
			background: var(--accent);
			box-shadow: none;
			border-radius: 0.25rem;

			img {
				transform: rotate(-10deg);
				filter: brightness(20);
			}

			span {
				box-shadow: 0 0 0 2px var(--white);
			}
		}
	}

	> img {
		width: 10rem;
		height: 6rem;
		object-fit: fill;
	}
}
