.brand {
	min-width: 15rem;
	height: 16rem;
	position: relative;
	cursor: pointer;
}

.brand * {
	transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1);
	user-select: none;
}

.brand div {
	width: 14rem;
	height: 100%;
	overflow: hidden;
	border-radius: 0.5rem;
}

.brand [alt="background" i] {
	filter: grayscale(1);
}

.brand [alt="character" i] {
	position: absolute;
	bottom: 0;
	right: -1rem;
	filter: grayscale(0.3);
}

.brand [alt="logo" i] {
	position: absolute;
	left: 1.5rem;
	right: 0;
	bottom: -3rem;
	height: 6rem;
	object-fit: contain;
}

.brand:hover {
	[alt="character" i] {
		scale: 1.1;
		filter: grayscale(0);
		rotate: -5deg;
	}
	[alt="logo" i] {
		scale: 1.3;
	}
	[alt="background" i] {
		scale: 1.1;
		rotate: 5deg;
		filter: grayscale(0);
	}
}
