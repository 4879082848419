.landing {
	position: relative;
	height: calc(100vh - 6.5rem);

	div {
		width: calc(100% - 6rem);
		max-width: 64rem;
		padding-top: 12rem;
		gap: 2rem;
		z-index: 1;

		@media screen and (min-width: 140rem) {
			max-width: 62rem;
		}

		@media screen and (min-width: 160rem) {
			max-width: 78rem;
		}
	}

	img {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	h1 {
		font-size: 4rem;
		text-shadow: 1px 1px 0.25rem rgba(0, 0, 0, 0.5);
		line-height: 4rem;
	}

	h1,
	p {
		width: 50%;
		text-shadow: 1px 1px 0.25rem rgba(0, 0, 0, 0.5);
	}

	* {
		color: var(--white);
	}

	&::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		background: linear-gradient(
				rgba(255, 255, 255, 0) 0%,
				rgba(255, 255, 255, 0) 80%,
				rgba(255, 255, 255, 1) 100%
			),
			linear-gradient(
				90deg,
				rgba(0, 0, 0, 0.5) 0%,
				rgba(0, 0, 0, 0) 30%,
				rgba(0, 0, 0, 0) 70%,
				rgba(0, 0, 0, 0.5) 100%
			);
	}

	&.left {
		h1,
		h6,
		p {
			text-align: left;
		}

		div {
			align-items: flex-start;
		}
	}

	&.right {
		h1,
		h6,
		p {
			text-align: right;
		}

		div {
			align-items: flex-end;
		}
	}
}

input[type="search"] {
	width: 100%;
	max-width: 24rem;
	line-height: 2.5rem;
	padding: 0 0.75rem;
	box-shadow: 0 0 0 0 var(--accent), inset 0 0 0 0 var(--accent);
	border-radius: 0.25rem;
	align-self: flex-start;
	background: var(--contrast--050);
	grid-column: 1 / -1;

	&:focus {
		box-shadow: 0 0 0 1px var(--accent), inset 0 0 0 1px var(--accent);
	}

	&::placeholder {
		color: var(--contrast--300);
	}
}
