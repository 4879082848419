div:has(> label + input) {
	width: 100%;

	label {
		cursor: text;
		user-select: none;
		line-height: 1rem;
		font-size: 14px;
		color: var(--contrast--700);
		padding-bottom: 0.25rem;
	}

	label:has(+ input[required]) {
		line-height: 1rem;
		font-size: 14px;
		&::before {
			content: "* ";
			color: var(--accent);
			line-height: 1rem;
			font-size: 14px;
		}
	}

	input {
		box-shadow: inset 0 0 0 2px var(--contrast--100);
		line-height: 2.5rem;
		padding: 0 0.5rem;
		border-radius: 0.5rem;
		width: 100%;
		background: var(--contrast--050);
		text-wrap: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		&::placeholder {
			color: var(--contrast);
			font-style: italic;
			opacity: 0.25;
		}

		&:hover {
			box-shadow: inset 0 0 0 2px var(--accent--300);
		}

		&:focus {
			box-shadow: inset 0 0 0 2px var(--accent);
		}
	}
}
