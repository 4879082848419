.one-piece-banner {
	min-height: 446px;
	position: relative;
	margin-top: 4rem;

	* {
		user-select: none;
	}

	a {
		text-decoration: none;
		background: var(--accent);
		color: var(--white);
		border: none;
		border-radius: 0.25rem;
		padding: 0.5rem 1rem;
		font-weight: 500;
		cursor: pointer;
		width: fit-content;
		transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1);

		&:hover {
			color: var(--white);
			box-shadow: 0 4px 0 0 var(--lipstick--500);
			background: var(--lipstick);
			transform: translateY(-0.25rem);

			&:active {
				transform: translateY(0);
				box-shadow: 0 0 0 0 var(--lipstick--500);
			}
		}
	}

	> div:first-child {
		position: relative;
		width: 100%;
		min-height: 446px;
		overflow: hidden;

		div {
			position: absolute;
			bottom: 0;
			right: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(
				90deg,
				rgba(25, 66, 146, 0.8) 0%,
				rgba(115, 115, 115, 0) 100%
			);
			mix-blend-mode: multiply;
		}

		* {
			transition: all 800ms;
		}

		[alt="sky" i] {
			position: absolute;
			top: 0;
		}

		[alt="ocean" i] {
			position: absolute;
			bottom: 0;
			height: 100%;
			width: 100%;
			object-fit: fill;
		}

		[alt="thousand sunny" i] {
			position: absolute;
			width: 302px;
			height: 450px;
			bottom: -1rem;
			right: 36rem;
			rotate: 10deg;
		}
	}

	> div:not(:first-child) {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		max-width: 32rem;
		justify-content: center;
		gap: 1rem;
		margin-left: 8rem;

		* {
			color: var(--white);
		}
	}

	[alt="characters" i] {
		position: absolute;
		bottom: 0;
		right: 4rem;
		width: 642px;
		height: 530px;
		transition: all 800ms;
	}

	&:hover {
		[alt="sky" i] {
			scale: 1.02;
			transform-origin: top;
			filter: saturate(1.5) blur(4px);
		}

		[alt="ocean" i] {
			scale: 1.07;
			transform-origin: bottom;
			filter: saturate(1.5);
		}

		[alt="thousand sunny" i] {
			scale: 0.95;
			transform: rotate(-5deg);
			filter: blur(2px);
		}

		[alt="characters" i] {
			transform: scale(1.08);
			transform-origin: bottom;
		}
	}
}
