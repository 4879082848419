.catalogue {
	position: relative;
	gap: 2rem;
	padding: 4rem 0;
	padding-bottom: 16rem;

	* {
		user-select: none;
	}

	> div:has(input) {
		max-width: 64rem;
		width: 100%;
		flex-direction: row;
		justify-content: center;

		&:has(button) {
			justify-content: space-between;
		}
	}

	> p {
		color: var(--contrast--500);
	}

	.product-grid {
		display: grid;
		max-width: 64rem;
		width: 100%;
		gap: 2rem;
		grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));

		* {
			user-select: none;
		}

		article {
			min-width: 14rem;
		}
	}
}
