footer + div:has(> aside) {
	pointer-events: none;
	position: absolute;
	overflow: hidden;
	left: 0;
	top: 6.5rem;
	width: 100vw;
	height: calc(100vh - 6.5rem);
	z-index: 2;
	flex-direction: row;

	* {
		font-family: "Poppins", sans-serif;
		pointer-events: none;
	}

	> button {
		background: rgba(0, 0, 0, 0);
		justify-content: center;
		align-items: center;
		box-shadow: none;
		border-radius: 0;
		padding: 0;
		min-width: 100vw;
		height: 100%;
		width: 100%;
		font-size: 0;
		color: transparent;
		pointer-events: none;

		&:hover {
			background: rgba(0, 0, 0, 0);
			box-shadow: none;
			border-radius: 0;
			padding: 0;
			transform: translateY(0);
		}
	}

	aside {
		position: relative;
		display: flex;
		flex-direction: column;
		height: 100%;
		min-width: 40rem;
		max-width: 40rem;
		background: var(--white);
		border-width: 0;
		border-left-width: 0.5rem;
		border-style: solid;
		border-image: linear-gradient(to bottom, var(--accent), rgba(0, 0, 0, 0)) 1
			100%;
		cursor: default;

		> div:first-child {
			height: 100%;
			overflow-y: auto;
			padding: 2rem;
			gap: 2rem;
		}

		hr:last-child {
			display: none;
		}

		button {
			padding: 0.5rem 1rem;
		}

		> p {
			text-align: center;
			vertical-align: middle;
			line-height: calc(100vh - 11.5rem);
			opacity: 0.5;
			font-weight: 600;
		}

		article {
			display: flex;
			flex-direction: row;
			gap: 1rem;

			h6 {
				font-weight: 500;
				width: 100%;
			}

			* {
				user-select: none;
			}

			div:first-child {
				background: var(--contrast--050);
				border-radius: 0.5rem;
				box-shadow: inset 0 0 0 2px var(--contrast--100);
				align-items: center;
				justify-content: center;
				height: 4rem;

				img {
					min-width: 4rem;
					max-width: 4rem;
					height: 4rem;
					padding: 0.5rem;
					object-fit: contain;
					mix-blend-mode: multiply;
					pointer-events: none;
				}

				&:hover img {
					rotate: -5deg;
					scale: 1.3;
				}
			}

			> div:last-child {
				flex-direction: row;
				justify-content: flex-start;
				width: 100%;
				gap: 1rem;

				> div:has(span) {
					flex-direction: column;
					justify-content: space-between;
					min-width: 9rem;
				}

				span {
					color: var(--contrast);
					font-weight: 600;
					font-size: 1rem;
					min-width: 4.5rem;
					text-align: right;

					&:has(+ span) {
						opacity: 0.75;
					}

					&::before {
						font-size: 1rem;
						content: "$";
						font-weight: 600;
					}

					&:last-child {
						font-size: 1.5rem;

						&::before {
							content: "$";
							font-size: 1.5rem;
						}
					}
				}
			}
		}

		> hr {
			padding: 0 2rem;
			box-shadow: 0 0 0 2px var(--contrast--100);
			width: calc(100% - 4rem);
			border-radius: 4px;
		}

		> div:first-child + * + div:last-child {
			position: sticky;
			left: 0;
			bottom: 0;
			width: 100%;
			padding: 2rem;
			background: var(--white);
			flex-direction: row;
			align-items: end;
			justify-content: space-between;

			span {
				display: flex;
				flex-direction: column;
				font-family: "Poppins";
				font-size: 1.5rem;
				font-weight: 700;

				&::before {
					content: "TOTAL";
					font-size: 0.75rem;
					font-weight: 500;
					text-align: end;
				}
			}

			button:hover {
				border-radius: 0.25rem;
				padding: 0.5rem 1rem;
			}
		}
	}

	&:has(> aside[open]) {
		* {
			pointer-events: all;
		}
		> button {
			background: rgba(0, 0, 0, 0.5);
			min-width: calc(100vw - 40rem);
			backdrop-filter: blur(0.25rem);

			&:hover {
				background: rgba(0, 0, 0, 0.65);
				backdrop-filter: blur(0.5rem);
				color: var(--white);
				font-size: 1rem;
			}
		}
	}
}
