main:has(table, aside) {
	min-height: calc(100vh - 12.5rem);
	background: var(--contrast--050);
	flex-direction: row;
	gap: 2rem;
	align-items: start;
	justify-content: center;
	padding: 4rem;

	* {
		font-family: "Poppins", sans-serif;
	}

	p,
	h2 {
		width: 100%;
	}

	h2 {
		color: var(--contrast);
		font-weight: 600;
	}

	p {
		color: var(--contrast--700);
	}

	> div:not(:last-child) {
		gap: 2rem;
		max-width: 48rem;
	}

	section {
		&:first-child {
			table {
				border-collapse: collapse;
				border-spacing: 4rem;

				thead {
					box-shadow: inset 0 -1px 0 0 var(--contrast--100);
				}

				th {
					font-weight: 700;
					padding: 1rem;
					color: var(--contrast--500);
					font-size: 0.75rem;

					&:first-child {
						padding-left: 0;
					}

					&:last-child {
						text-align: end;
						padding-right: 0;
					}
				}

				tr {
					box-shadow: inset 0 -1px 0 0 var(--contrast--100);

					&:last-child {
						box-shadow: none;

						td:first-child div {
							margin-bottom: 0;
						}
					}
				}

				td {
					vertical-align: top;

					&:not(:first-child) {
						padding: 1rem;
					}

					&:not(:nth-child(2)) {
						text-wrap: nowrap;
					}

					&:last-child {
						text-align: end;
						padding-right: 0;
						font-weight: 600;
					}

					&:first-child {
						div:has(img) {
							background: var(--contrast--050);
							border-radius: 0.5rem;
							box-shadow: inset 0 0 0 2px var(--contrast--100);
							align-items: center;
							justify-content: center;
							height: 6rem;
							margin: 1rem 0;

							img {
								max-width: 6rem;
								height: 6rem;
								padding: 0.5rem;
								object-fit: contain;
								mix-blend-mode: multiply;
								pointer-events: none;
							}

							&:hover img {
								rotate: -5deg;
								scale: 1.3;
							}
						}
					}
				}
			}
		}

		&:last-child {
			hr {
				margin: 1.5rem 0;
			}

			form {
				display: flex;
				flex-direction: column;
				width: 100%;
				gap: 1rem;

				> div:has(*:not(label) + *:not(input)) {
					flex-direction: row;
					gap: 1rem;
					align-items: end;
				}

				> div {
					max-width: 32rem;
				}
			}
		}
	}

	section,
	aside {
		display: flex;
		background: var(--white);
		padding: 2rem;
		border-radius: 1rem;
		box-shadow: 0 0 0 1px var(--contrast--100);
		justify-content: start !important;
		gap: 1rem;
	}

	div:has(aside) {
		position: sticky;
		top: 2rem;
		gap: 1rem;
		min-width: 24rem;
		max-width: 24rem;
		color: var(--contrast--500);
		font-weight: 300;
		font-size: 0.75rem;
		text-align: center;
	}

	aside {
		flex-direction: column;
		color: var(--contrast);
		text-align: left;

		p {
			font-size: 0.75rem;
			line-height: 1rem;
			text-wrap: pretty;
		}

		strong {
			font-weight: 600;
			font-size: 0.75rem;
		}

		table {
			tr {
				box-shadow: none;
				max-width: 50px;

				td:first-child {
					max-width: 13rem;
					overflow: hidden;
					text-wrap: nowrap;
					text-overflow: ellipsis;
				}

				&:last-child td {
					font-weight: 600;
					font-size: 1rem;
					color: var(--contrast);
					padding: 1rem;

					&:last-child::before {
						content: "$ ";
						font-size: 1rem;
						font-weight: 600;
					}
				}
			}

			td {
				font-size: 0.75rem;
				border-collapse: collapse;
				padding: 0.25rem 0 !important;
				color: var(--contrast--500);

				&:last-child {
					font-weight: 400;
					width: 100%;
					color: var(--contrast);
					text-align: end;
				}
			}
		}
	}

	input[type="submit"] {
		background: var(--accent);
		border-radius: 0.25rem;
		color: var(--white);
		padding: 0.5rem;
		cursor: pointer;
		font-weight: 500;

		&:hover {
			transform: translateY(-0.25rem);
			box-shadow: 0 4px 0 0 var(--lipstick--500);
			background: var(--lipstick);
		}

		&:disabled {
			background: var(--contrast--500);
			&:hover {
				transform: translateY(0);
				box-shadow: none;
				background: var(--contrast--500);
			}
		}
	}

	.address-fields {
		line-height: 2.5rem;
	}
}

main:has(> div:first-child aside) {
	justify-content: center;
	align-items: center;

	h1,
	p {
		text-align: center;
	}

	a,
	strong {
		font-size: 0.75rem;
		line-height: 1rem;
		color: var(--accent);
		text-decoration: none;
		font-weight: 600;
	}

	a:hover {
		text-decoration: underline;
	}

	> div {
		transform: translateY(-4rem);
		max-width: 28rem;
		gap: 2rem;

		div {
			flex-direction: row;
			justify-content: center;
			gap: 2rem;
			position: relative;

			p {
				position: absolute;
				width: 100%;
				bottom: 0;
				font-size: 0.75rem;
				line-height: 1rem;
				transform: translateY(1rem) scale(0.5);
				opacity: 0;

				&[copied="true"]:before {
					content: "✔ ";
					color: #46b361;
					font-size: 0.75rem;
					line-height: 1rem;
				}
			}

			&:has(img:hover) p {
				opacity: 1;
				transform: translateY(1rem) scale(1);
			}
		}

		> p {
			font-size: 0.75rem;
			line-height: 1rem;
			text-wrap: pretty;

			strong {
				font-size: 0.75rem;
				line-height: 1rem;
				font-weight: 600;
				color: var(--contrast--700);
			}
		}

		img {
			height: 3.5rem;
			object-fit: contain;
			object-position: center;
			padding: 1rem 0;
			user-select: none;
			cursor: pointer;

			&:active {
				scale: 0.9;
			}
		}

		span {
			position: relative;
			font: inherit;
			p {
				width: 100%;
				position: absolute;
				top: 0;
				opacity: 0;
				font-size: 0.75rem;
				line-height: 1rem;
				transform: translateY(1rem);
			}
		}

		&:has(img:hover:first-child) {
			span :first-child {
				opacity: 1;
				transform: translateY(0);
			}
		}

		&:has(img:hover:last-child) {
			span :last-child {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}
}
