section:has(.event) {
	padding: 8rem 4rem;
	gap: 6rem;
	perspective: 100rem;

	&:has(> *:hover) div:not(:hover) {
		filter: blur(0.25rem) opacity(0.5) grayscale(0.5);
		scale: 0.9;
	}
}
