.catalogue article {
	display: flex;
	flex-direction: column;
	transition: all 300ms ease;
	cursor: pointer;
	min-width: 14rem;
	position: relative;

	&[featured="true"] {
		> div {
			box-shadow: 0 0 0 0.25rem var(--lipstick);
		}

		&:hover > div {
			box-shadow: 0 0 0 0 var(--lipstick--000);
		}
	}

	&[stock="false"] {
		filter: grayscale(1);
		opacity: 0.5;
		pointer-events: none;
	}

	&:hover > div {
		background: linear-gradient(
			var(--contrast--000) 0%,
			var(--lipstick--000) 100%
		);
	}

	div {
		background: linear-gradient(
			var(--contrast--050) 0%,
			var(--lipstick--100) 100%
		);
		border-radius: 0.5rem;
	}

	img {
		height: 10rem;
		width: 100%;
		padding: 1rem;
		object-fit: contain;
		mix-blend-mode: multiply;
		pointer-events: none;
	}

	h6 {
		font-family: "Poppins";
		font-weight: 600;
		line-height: 1rem;
		height: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		margin-top: 1rem;
	}

	span {
		&:not(:last-child) {
			font-family: "Poppins";
			position: absolute;
			top: 0.5rem;
			left: 0.5rem;
			background: var(--thunder);
			color: var(--white);
			padding-left: 2rem;
			padding-right: 0.25rem;
			line-height: 2rem;
			height: 2rem;
			border-radius: var(--radius--min);
			background-image: url("../../assets/icons/discount.svg");
			background-repeat: no-repeat;
			background-position: 0.25rem;

			&::before {
				content: "-";
			}

			&::after {
				content: "%";
			}
		}

		&:last-child {
			color: var(--thunder);
			font-weight: 600;
			width: fit-content;
			line-height: 2rem;
			font-family: "Poppins";

			&::before {
				content: "$";
				font-weight: 600;
			}

			&::after {
				content: "" attr(data-content);
				padding-left: 0.5rem;
				color: var(--contrast);
				font-size: 0.75rem;
				opacity: 0.5;
				font-family: "Poppins";
				font-weight: 500;
				text-decoration-line: line-through;
			}
		}
	}

	&:hover img {
		rotate: -5deg;
		scale: 1.3;
	}
}
